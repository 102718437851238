var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex mt-2"},[_c('div',{staticClass:"navigate-space"},[(_vm.testIndex != 0 && _vm.testsFilter.length > 1)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Ir al anterior`),expression:"`Ir al anterior`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"navigate-left",attrs:{"size":"sm","variant":"none"},on:{"click":_vm.previousTabs}},[_c('b-icon',{attrs:{"icon":"chevron-left"}})],1):_vm._e()],1),_c('strong',{staticClass:"border-bottom pb-1"},[_vm._v("Evaluar "+_vm._s(_vm.test.title))]),_c('div',{staticClass:"navigate-space"},[(_vm.testIndex != _vm.testsFilter.length - 1)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Ir al siguiente`),expression:"`Ir al siguiente`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"navigate-right",attrs:{"size":"sm","variant":"none"},on:{"click":_vm.nextTabs}},[_c('b-icon',{attrs:{"icon":"chevron-right"}})],1):_vm._e()],1)]),(_vm.evaluateeTest)?_c('div',{staticClass:"mt-3 border-bottom"},[_c('div',{staticClass:"ml-3 text-left mb-2"},[_vm._v(" Inicio de "),_c('strong',[_vm._v(_vm._s(_vm.test.title))]),_vm._v(" por parte del evaluado: "),(_vm.evaluateeTest.start_time == null)?_c('strong',{staticClass:"text-red"},[_vm._v(" No contestado ")]):_c('strong',[_vm._v(" "+_vm._s(_vm._f("FormatToDateTime")(_vm.evaluateeTest.start_time))+" ")])])]):_vm._e(),_c('div',{staticClass:"mt-3"},[_c('b-form-group',{staticClass:"mb-0 mx-3 py-1 text-left border-bottom",attrs:{"label":_vm.evaluateeTest && _vm.evaluateeTest.manual_score == null
          ? 'Puntaje propuesto'
          : 'Puntaje Máximo',"label-cols":"4","label-cols-sm":"8"}},[_c('div',{staticClass:"mt-2"},[_c('span',{staticClass:"automated-score"},[(_vm.evaluateeTest && _vm.evaluateeTest.manual_score == null)?_c('span',[_vm._v(" "+_vm._s(_vm.automatedScore)+" / ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.test.max_score)+" ")])])]),_c('div',{staticClass:"d-flex mb-0 mx-3 py-1 text-left border-bottom"},[_c('div',{staticClass:"mt-1"},[_vm._v("Puntaje asignado:")]),_c('div',{staticClass:"mr-0 ml-auto"},[_c('div',{staticClass:"d-flex"},[(_vm.evaluateeTest && _vm.evaluateeTest.manual_score != null)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              'Aplicar Puntaje Automático'
            ),expression:"\n              'Aplicar Puntaje Automático'\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"m-0 mr-1 p-0",attrs:{"variant":"none"},on:{"click":function($event){return _vm.patchEvaluateeTest({ manual_score: null })}}},[_c('b-icon',{staticStyle:{"color":"white"},attrs:{"icon":"arrow-repeat"}})],1):_vm._e(),_c('b-form-input',{staticClass:"mr-1 manual-score-input",attrs:{"id":"manual-score-input","type":"number","disabled":!_vm.user_id || isNaN(_vm.evaluatee_test.id) || !_vm.closedEvaluationScore,"min":"0"},model:{value:(_vm.evaluatee_test.manual_score),callback:function ($$v) {_vm.$set(_vm.evaluatee_test, "manual_score", _vm._n($$v))},expression:"evaluatee_test.manual_score"}})],1)])]),(
        _vm.evaluatee_test &&
        _vm.institution &&
        _vm.institution.internal_use_id != 'duoc_uc'
      )?_c('div',{staticClass:"instrument-grade-input border-bottom mx-3 pt-1 pb-1"},[_c('span',{staticClass:"text-left"},[_vm._v("Nota del "+_vm._s(_vm.$getVisibleNames("evaluations2.test", false, "Instrumento"))+": ")]),_c('div',{staticClass:"mr-4"},[_vm._v(" "+_vm._s(_vm.getInstrumentFinalScore())+" ")])]):_vm._e(),(_vm.evaluateeTest && _vm.evaluateeTest.student_attachments.length > 0)?_c('div',{staticClass:"mt-3 mb-4 border-bottom text-left"},[[_c('span',{staticClass:"ml-3"},[_vm._v(" Adjuntos del Evaluado: ")]),_c('FileContainer',{staticClass:"ml-3",attrs:{"Files":_vm.evaluateeTest.student_attachments,"allows_crud":false}})]],2):_vm._e(),(_vm.evaluatee_test)?_c('div',{staticClass:"border-bottom mx-2"},[_vm._m(0),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(
          _vm.user_id && _vm.institution && _vm.closedEvaluationScore
            ? 'Editar Retroalimentación'
            : ''
        ),expression:"\n          user_id && institution && closedEvaluationScore\n            ? 'Editar Retroalimentación'\n            : ''\n        ",modifiers:{"v-secondary":true}}],staticClass:"instrument-form-feedback rich-text-content mb-2 mt-2 ml-2",domProps:{"innerHTML":_vm._s(_vm.evaluatee_test.feedback)},on:{"click":function($event){if (_vm.user_id && _vm.institution && _vm.closedEvaluationScore)
            _vm.$bvModal.show(
              `edit-evaluatee_test-feedback-modal-${_vm.evaluatee_test.id}`
            );}}}),_c('b-modal',{attrs:{"id":`edit-evaluatee_test-feedback-modal-${_vm.evaluatee_test.id}`,"title":"Editar Retroalimentación","size":"lg","hide-footer":""}},[_c('NewRichTextEditor',{attrs:{"Object":_vm.evaluatee_test,"Text":_vm.evaluatee_test.feedback,"permit_blank":true},on:{"save":_vm.patchFeedback,"close":function($event){return _vm.$bvModal.hide(
              `edit-evaluatee_test-feedback-modal-${_vm.evaluatee_test.id}`
            )}}})],1)],1):_vm._e(),(_vm.user_id && _vm.institution && _vm.closedEvaluationScore)?_c('div',{staticClass:"btn-container ml-2"},[_c('b-button',{staticClass:"btn-save mt-2 ml-2 mr-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.patchQuestionTestScore(_vm.evaluatee_test.manual_score)}}},[_vm._v(" Guardar ")]),(!_vm.evaluatee_test.is_published)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
          `Publicar ${_vm.$getVisibleNames(
            'evaluations2.test',
            false,
            'Instrumento'
          )}`
        ),expression:"\n          `Publicar ${$getVisibleNames(\n            'evaluations2.test',\n            false,\n            'Instrumento'\n          )}`\n        ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"btn-save mt-2 ml-2 mr-2",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.publicInstrument(_vm.evaluatee_test.manual_score)}}},[_vm._v(" Publicar ")]):_vm._e()],1):_vm._e(),(
        _vm.user_id &&
        _vm.institution &&
        _vm.closedEvaluationScore &&
        _vm.newTestGroup &&
        !isNaN(_vm.evaluatee_test.id)
      )?_c('div',{staticClass:"btn-container ml-2"},[_c('b-button',{staticClass:"btn-save mt-2 ml-2 mr-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
            `copy-evaluatee_test-student-group-modal-${_vm.evaluatee_test.id}${_vm.test.id}`
          )}}},[_vm._v(" Replicar nota al grupo ")]),_c('b-modal',{attrs:{"id":`copy-evaluatee_test-student-group-modal-${_vm.evaluatee_test.id}${_vm.test.id}`,"title":"Seleccionar Evaluados","size":"lg","hide-footer":""},on:{"hide":() => {
            _vm.selected_users = [];
            _vm.select_all = false;
          }}},[_c('GenericBTable',{attrs:{"display_id":`GenericBTable-copy-evaluatee_test-student-group-modal-${_vm.evaluatee_test.id}${_vm.test.id}`,"items":_vm.filterListReplyScore,"fields":_vm.fields,"columns_display":false,"show_pagination":true,"search_filter":false,"default_striped":false,"default_borderless":false,"pagination":100},scopedSlots:_vm._u([{key:"head(selected)",fn:function(){return [_c('b-form-checkbox',{on:{"change":_vm.selectAllUsers},model:{value:(_vm.select_all),callback:function ($$v) {_vm.select_all=$$v},expression:"select_all"}})]},proxy:true},{key:"cell(selected)",fn:function(row){return [_c('div',{staticClass:"d-flex"},[_c('b-checkbox',{staticClass:"ml-auto mr-auto",attrs:{"checked":_vm.selected_users.includes(row.item.id)},on:{"change":function($event){return _vm.changeSelected(row.item)}}})],1)]}},{key:"cell(first_name)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.user_data ? row.item.user_data.identifier : "")+" "+_vm._s(row.item.first_name)+" "+_vm._s(row.item.last_name)+" ")]}}],null,false,791259309)}),_c('div',{staticClass:"d-flex"},[(_vm.selected_users.length > 0 && _vm.show_button_reply_score)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              `${_vm.selected_users.length} evaluados seleccionados.`
            ),expression:"\n              `${selected_users.length} evaluados seleccionados.`\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"ml-auto mr-0",attrs:{"size":"sm"},on:{"click":_vm.replcateTestScore}},[_vm._v(" Aplicar ")]):_c('b-button',{staticClass:"ml-auto mr-0",attrs:{"size":"sm","disabled":""}},[_vm._v(" Aplicar")])],1)],1)],1):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left ml-2 mt-2"},[_c('span',[_vm._v("Retroalimentación:")])])
}]

export { render, staticRenderFns }